<template lang="pug">
  v-container(fluid)
    v-data-table(
      :items="clientApplicationsList"
      :headers="headers"
      :loading="clientApplicationsGetting"
      :search="search"
    )
      template(#body.prepend)
        tr
          td(:colspan="headers.length")
            v-row
              v-col(cols="3")
                v-text-field(
                  label="search"
                  v-model="search"
                  append-icon="mdi-magnify"
                )
      template(#item.created_at="{ value }")
        span {{ value.dateFormat({ hour: 'numeric', minute: 'numeric' }) }}
</template>
<script>
import { requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import clientApplicationRepository from '@/repositories/client-application.repository'

const [clientApplicationVars, clientApplicationVarNames] = requestVars({ identifier: 'client-applications' })

const clientApplicationHandler = new VueRequestHandler(null, clientApplicationVarNames)

export default {
  name: 'VdClientApplicationTable',
  data() {
    return {
      ...clientApplicationVars,
      search: null,
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Message', value: 'message' },
        { text: 'Date', value: 'created_at' },
      ],
    }
  },
  created() {
    this.getClientApplications()
    this.websocketInit()
  },
  methods: {
    websocketInit() {
      const { echo } = this.$store.state.websocket
      const storeEvent = ({ data, model }) => {
        if (model === 'ClientApplication') {
          this.clientApplicationsList.unshift(data) 
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', storeEvent)
    },
    async getClientApplications() {
      try {
        const handler = clientApplicationHandler
        const repository = clientApplicationRepository.index
        handler.setVue(this)
        await handler.execute(repository)
      } catch (e) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: e.message,
        })
      }
    },
  },
}
</script>
